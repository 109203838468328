//Mobile------------------------------------------------------------------------------------------------------
.card-news {
	padding: 24px;
	background-color: #FFF;
	border: 1px solid var(--card-border);
	filter: drop-shadow(0px 79px 31px rgba(130, 168, 192, 0.01)) drop-shadow(0px 44px 27px rgba(130, 168, 192, 0.03)) drop-shadow(0px 20px 20px rgba(130, 168, 192, 0.05)) drop-shadow(0px 5px 11px rgba(130, 168, 192, 0.06)) drop-shadow(0px 0px 0px rgba(130, 168, 192, 0.06));
	min-height: 100%;
	border-radius: 4px;
	overflow: hidden;
	.image {
		margin: -24px -24px auto -24px;
		padding-top: 41.59%;
		&.small-image {
			padding-top: 31.11%;
		}
	}
	span.card-category {
		font-size: 0.875rem;
		text-transform: uppercase;
		font-weight: 600;
		color: var(--text-color);
		line-height: 1.2;
		display: flex;
		justify-content: end;
		text-align: end;
	}
	p {
		font-size: 1rem;
		color: var(--light-text);
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;

	}
	h3,h4 {
		a{
			text-decoration: none;
			color: var(--dark-blue);
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;

		}
	}
	h4 {
		font-size: 1.33333333rem;
	}
	.bg-icon {
		width: 50px;
		height: 50px;
		fill:  rgba(0, 95, 115, 0.08);
		position: absolute;
		left: -11px;
    	bottom: -10px;
	}
	.date {
		font-size: 0.875rem;
		font-weight: 600;
		color: var(--light-text);
	}
	.doc-rows:not(:last-child) {
		border-bottom: 1px solid var(--blue-light);
		padding-bottom: 24px;
	}
	.collapsable .icon {
		transition: all 0.3s;
	}
	.collapsable[aria-expanded="true"] {
		.icon {
			transform: rotate(180deg);
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

//Moble only ---------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {

}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.card-news {
		&.padding-y-small {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		.image {
			padding-top: 69.27%
		}
		span.card-category {
			font-size: 0.778rem;
		}
		p {
			font-size: 0.889rem;
			&.text-3-rows {
				height: 72px;
			}
			&.text-2-rows {
				height: 48px;
				-webkit-line-clamp: 2;
			}
			&.text-4-rows {
				height: 96px;
				-webkit-line-clamp: 4;
			}
			&.text-1-rows {
				-webkit-line-clamp: 1;
			}
		}
		h3 {
			a {
				&.title-2-rows {
					display: block;
					height: 64px;
				}
			}
		}
		.date {
			font-size: 0.7777777777777778rem;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	h3 {
		a:hover{
			text-decoration: underline;
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
