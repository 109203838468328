//Mobile------------------------------------------------------------------------------------------------------
.it-header-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100000;
	.it-nav-wrapper {
		.it-header-navbar-wrapper {
			position: relative;
			left: auto;
			top: auto;
			margin-top: 0;
			nav {
				padding: 0;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-down(lg) {
	.it-header-wrapper {
		&.transparent-header {
			.it-nav-wrapper {
				background-color: var(--header-center-mob-bg-color);
			}
			.it-header-center-content-wrapper {
				.it-brand-wrapper a {
					color: #FFF;
				}
				.it-brand-wrapper a .icon{
					fill: #FFF;
				}
			}
		}
		.it-nav-wrapper {
			padding: 8px 0;
			border-bottom:1px solid var(--border-color-negative);
			background-color: #FFF;
		}
	}
}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.it-header-wrapper {
		border-bottom: 1px solid var(--border-color-negative);
		&.sticky-header {
			border-bottom: 1px solid transparent;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
