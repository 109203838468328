//Mobile------------------------------------------------------------------------------------------------------
.tabs {
	.nav-tabs {
		box-shadow: none;
		border-bottom: none;
		padding-bottom: 1px;
		background-color: transparent;
		&.small-tabs {
			font-size:  0.8888888888888888rem;
		}
		.nav-link {
			padding: 0.8888888888888888rem 1.3333333333em;
			color: var(--color-primary);
			border: 1px solid var(--card-border);
			background-color: #fff;
			white-space: inherit;
			&.active {
				background-color: var(--tabs-active);
				border-color: var(--tabs-active);
				color: #fff;
			}
		}
		.nav-item {
			&:first-child {
				.nav-link  {
					border-top-left-radius: var(--button-radius);
					border-bottom-left-radius: var(--button-radius);
				}
			}
			&:last-child {
				.nav-link  {
					border-top-right-radius: var(--button-radius);
					border-bottom-right-radius: var(--button-radius);
				}
			}
		}
	}
	.dropdown-toggle.dopdown-tab {
		padding: 16px 24px 16px 16px;
		background-color: var(--blue-light);
		border-radius: 4px;
		color: var(--color-primary);
		font-weight: 600;
		text-decoration: none;
		font-size: 1.25rem;
		position: relative;
		z-index: 10;
		.icon {
			fill: var(--color-primary);
			transform-origin: center;
			flex-shrink: 0;
		}
		&:after {
			display: none;
		}
		&[aria-expanded="true"] {
			.icon {
				transform: rotate(180deg);
			}
		}
	}
	.dropdown-menu.tab-dropdown {
		width: 100%;
		top: -18px !important;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		li {
			list-style:none
		}
		.link-list {
			padding-left: 0;
		}
		.dropdown-item {
			color: var(--color-primary);
			font-size: 1.125rem;
			&.active {
				font-weight: 700;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.tabs {
		.nav-tabs {
			.nav-link {
				padding: 0.8888888888888888rem 1.3333333333em;
				min-width: 248px;
			}
		}
	}
}



//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

	.tabs {
		.nav-tabs {
			&.flex-nowrap {
				.nav-item {
					width: auto;
				}
				.nav-link {
					min-width: auto;
				}
			}
			&.small-tabs {
				.nav-link {
					height: 56px;
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
