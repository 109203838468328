//Mobile------------------------------------------------------------------------------------------------------
.simple-cta {
	color: var(--color-primary);
	text-transform: uppercase;
	font-size: 0.875rem;
	font-weight: 700;
	text-decoration: none;
	background: none;
	border: none;
	.icon {
		fill: var(--color-primary);
		width: 17px;
		height: 17px;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.simple-cta {
		font-size: 0.7777777777777778rem;
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.simple-cta {
		&:hover {
			text-decoration: underline;
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
