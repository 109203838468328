//Mobile------------------------------------------------------------------------------------------------------
.hero-search {
	@extend .hero-full-search;
	overflow: visible;
	.breadcrumb-container {
		padding-top: 28px;
		padding-bottom: 34px;
	}
	.text-zone {
		padding-top: 0px;
		padding-bottom: 0;
	}
	.background-right {
		img {
			//object-fit: contain;
		}
	}
	.image {
		padding-top: 0;
	}
	.collapse, .collapsing {
		position: absolute !important;
		top: 100%;
		left: 0 ;
		right:0 ;
		z-index: 10;
	}
	.dropdown-panel {
		margin-bottom: 36px;
	}
	&.double-branded {
		&:after {
			content: '';
			display: block;
			position: absolute;
			inset: 0;
			background: url('../assets/double-circle.svg') no-repeat;
			background-size: 120%;
			background-position: 65% 33%;
			pointer-events: none;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.hero-search {
		.background-right {
			padding-top: 0;
			&.gradient {
				width: 50.0000%;
				min-height: auto;
				left: auto;
				right: 0;
				bottom: 0;
				img {
					object-fit: cover;
					object-position: right;
					right: 0;
					left: auto;
					height: calc(100% - var(--header-tab-size));
					bottom: 0;
					top: auto;
				}
			}
		}
		.collapse, .collapsing {
			position: absolute !important;
			top: 100%;
			left: var(--default-x-padding-desk) ;
			right: var(--default-x-padding-desk) ;
		}

		&.double-branded:after {
			background-size: 66%;
			background-position: center 33%;
		}

	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.hero-search {
		.search-panel {
			z-index: 10;
			.filters-wrapper {
				height: 58px;
			}
			.filters {
				height: 100%;
			}
			.btn-filter {
				height: 100%;
			}
		}
		.background-right {

			&.gradient {
				width: 50.0000%;
				left: auto;
				right: 0;
				bottom: 0;
				img {
					object-fit: cover;
					object-position: right;
					right: 0;
					left: auto;
					height: calc(100% - 160px);
					bottom: 0;
					top: auto;
				}
			}

		}
		.breadcrumb-container {
			padding-top: 28px;
			padding-bottom: 28px;
		}
		.text-zone {
			width: auto;
			padding-top: 0;
			padding-bottom: 64px;
		}
		&.branded {
			&:after {
				background-position: 120% 280px;
			}
		}
		&.hero-small {
			overflow: inherit;
			.search-panel {
				padding-bottom: 0;
				margin-bottom: -29px;
			}
			.dropdown-panel {
				margin-bottom: -32px;
				padding-bottom: 0;
			}
			p {
				margin-bottom: 0;
			}
		}
		&.has-panel {
			.text-zone {
				padding-bottom: 24px;
			}
		}
		.dropdown-panel {
			padding-left: var(--default-x-padding-desk);
			padding-right: var(--default-x-padding-desk);
			.dropdown-menu {
				right: calc(var(--default-x-padding-desk) * 2) !important;
		  	}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
