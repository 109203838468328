:root {
	//-colors
	--color-primary:#216FA3;
	--text-color:#1A1A1A;
	--body-background: #fff;
	--border-color-negative:#BEC0C1;
	--dark-blue:#001D41;
	--header-nav-default-color:#002D64;
	--blue-light: #E8F2FC;
	--blue-lighter: #F2F7FC;
	--card-border: #E9F0F5;
	--light-text: #2F475E;
	--pill-dark : #213343;
	--green : #D7D150;
	--pill-light: #E5F1FF;
	--footer-blue: #12213A;
	--footer-dark-blue: #0E192D;
	--hero-blue: #0d2f67;
	--feedback-blue: #1C3256;
	--tabs-active: #0F3E75;
	--color-dida: var(--light-text);
	--crosslink-background: #F5F9FC;
	--read-more-bg: #EDF4F7;
	--map-icons:  #41759B;
	--contacts-cta-bg: #003270;
	--carousel-active-color: var(--color-primary);
	--banner-background: #001D41;
	//-spaces
	--default-x-padding-mob:16px;
	--default-y-padding-mob:30px;
	--big-y-padding-mob:48px;
	--default-x-padding-desk:48px;
	--default-y-padding-desk:70px;
	--big-y-padding-desk:100px;
	--header-desk-size:233px;
	--header-tab-size: 66px;
	--header-mob-size:48px;
	--header-desk-collapsed-size:107px;

	//-button
	--button-radius:4px;
	--button-padding: 18px;
	--button-primary-bg: var(--color-primary);
	--button-primary-color: #FFF;
	--button-negative-color: var(--color-primary);
	//-header-slim
	--header-slim-bg-color:#001D41;
	--header-slim-right-font-size: 0.889rem;
	--header-slim-sticky-font-size:0.889rem;
	--header-slim-sticky-bg:#FFF;
	--header-slim-sticky-link-color:var(--color-primary);
	--header-slim-sticky-brand-color:var(--text-color);
	//-header-center
	--header-center-mob-size:32px;
	--header-center-desk-size:72px;
	--header-center-mob-title-size:0.889rem;
	--header-center-mob-subtitle-size:0.667rem;
	--header-center-mob-bg-color: var(--header-slim-bg-color);
	//-header-nav
	--header-nav-burger-size: 24px;
	--header-nav-burger-color: #FFF;
	--header-nav-burger-font-size:0.875rem;
	--header-nav-heading-size:1.5rem;
	--header-nav-close-label-size:0.875rem;
	--header-nav-close-icon-wrapper-size:20px;
	--header-nav-close-icon-size:12px;
	--header-nav-link-size:1.25rem;
	--header-nav-link-border: 1px solid #939596;
	--header-nav-sublink-size: 1rem;
	--header-sublinks-border:1px solid #E9F0F5;
	--header-nav-link-desk-color: #FFF;
	--header-nav-dropdown-padding:24px 32px 32px;
	--header-nav-dropdown-width:300px;
	//-dropdown
	--dropdown-border-radius:4px;
	--dropdown-box-shadow: 0px 423px 169px rgba(0, 43, 61, 0.02), 0px 238px 143px rgba(0, 43, 61, 0.06), 0px 106px 106px rgba(0, 43, 61, 0.1), 0px 26px 58px rgba(0, 43, 61, 0.12), 0px 0px 0px rgba(0, 43, 61, 0.12);
	//icon
	--icon-size: 20px;
	--icon-size-xs:12px;
	//input
	--search-panel-content-bg: #fff;
	--search-panel-content-border: 1px solid #E9F0F5;
	//chip
	--chip-color-bg: transparent;
	--chip-color: var(--color-primary);
	--chip-border: 2px solid var(--color-primary);
	--chip-active-color-bg: var(--tabs-active);
	--chip-active-color: #fff;
	//text
	--text-riproduci: "Riproduci";
	--text-blank: "(apre in un'altra finestra)";
}

[lang="en"] {
	//text
	--text-riproduci: "Play video";
	--text-blank: "(opens in a new window)";
}


.dark-theme {
	--body-background: #03111A;

	main {
		//-colors
		--text-color:#fff;
		--bs-body-color: var(--text-color);
		--dark-blue: var(--text-color);
		--carousel-active-color: var(--text-color);

		//input
		--search-panel-content-bg: #030B15
		//chip
		--chip-color-bg: var(--body-background);
		--chip-color: var(--bs-body-color);
		--chip-border: 2px solid #666;
		--chip-active-color-bg: var(--bs-body-color);
		--chip-active-color: var(--body-background);
	}
}

//dark theme
@media (prefers-color-scheme: dark) {
	:root {
		//-general
	}
}
.dark-theme {
	--body-background: #03111A;
}
