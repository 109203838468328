//Mobile------------------------------------------------------------------------------------------------------
.navscroll-page {
	padding-top: 30px;
}
.it-navscroll-wrapper {
	h3 {
		font-size: 0.7777777777777778rem;
	}
	&.navbar .link-list-wrapper ul li a {
		font-size: 0.8888888888888888rem;
	}
}

.left-nav {
	&.bs-is-sticky {
		z-index: 999 !important;
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}

@include media-breakpoint-down(lg) {
	.pagescroll-scroll-disabled {
		.navbar.it-bottom-navscroll {
			.custom-navbar-toggler {
				&:after {
					transform: rotate(0);
					margin-top: -10px;
				}
			}
		}
	}
	.navbar.it-bottom-navscroll {
		box-shadow: none;
		bottom: 24px;
		left: 16px;
		right: 16px;
		margin: 0;
		padding: 0;
		background-color: transparent;
		.custom-navbar-toggler {
			background-color: var(--color-primary);
			border-radius: 8px;
			padding: 12px 32px 12px 16px;
			position: relative;
			z-index: 101;
			font-size: 0.875rem;
			color: #FFF;
			text-transform: uppercase;
			font-weight: 600;
			position: relative;
			//height: 30px;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			span.it-list {
				display: none;
			}
			&:after {
				content : url('data:image/svg+xml; utf8, <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 8" style="enable-background:new 0 0 12 8;" xml:space="preserve"><path fill="white" d="M6,7.2L0.4,1.6l0.7-0.8L6,5.7l4.9-4.9l0.7,0.8L6,7.2z"/></svg>');
				display: block;
				width: 16px;
				height: 16px;
				position: absolute;
				right: 16px;
				top: 50%;
				margin-top: -6px;
				transition: all 0.3s;
				transform: rotate(180deg);
				transform-origin: center;

			}
			.icon {
				transform-origin: center;
				transform: rotate(180deg);
				transition: all 0.3s;
			}
		}
		.progress {
			display: none;
		}
		.overlay {
			background-color: transparent;
		}
		.navbar-collapsable.expanded {
			.menu-wrapper {
				transition: all .3s cubic-bezier(0.29, 0.85, 0.5, 0.99);
				max-height: calc(100vh - 90px);
			}
		}
		.menu-wrapper{
			bottom: 26px;
			left: 16px;
			right: 16px;
			background-color: var(--dark-blue);
			border-radius: 8px;
			max-height: 0;
			top: auto;
			overflow: hidden;
			padding:16px 0;
			transform: none;
			transition: all .3s cubic-bezier(0.29, 0.85, 0.5, 0.99);
			.scroll-container {
				overflow: hidden;
				overflow-y:auto ;
				max-height: calc(100vh - 120px);
				padding: 0 16px 32px;
			}
			.link-list-wrapper {
				ul {
					li{
						a{
							padding-left: 0;
							padding-right: 0;
							padding-top: 0;
							padding-bottom: 16px;
							span {
								color: #FFF;
								font-size: 1rem;
								font-weight: 600;
							}
							&.active {
								border: none;
							}
						}
						&:last-child {
							a {
								padding-bottom: 0;

							}
						}
					}
				}
			}
		}
	}
}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.navbar.it-right-side {
		border: none;
	}
	.left-nav.bs-is-sticky {
		top: calc(var(--header-desk-collapsed-size) + 48px) !important;
	}
	.navscroll-page {
		padding-top: 72px;
	}
	.navbar.it-navscroll-wrapper {
		.menu-wrapper {
			padding: 0;
		}
		h3 {
			padding: 0;
			font-size: 0.778rem;
			letter-spacing: 0;
			font-weight: 600;
			color: var(--text-color);
		}
		.progress {
			height: 1px;
		}
		.progress-bar {
			background-color: var(--color-primary);
		}
		.link-list-wrapper {
			ul {
				li {
					a {
						color: var(--color-primary);
						&.active {
							border-left: none;
							color: var(--dark-blue);
							span {
								color: inherit;
							}
						}
					}
				}
			}
		}
	}

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
