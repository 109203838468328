//Mobile------------------------------------------------------------------------------------------------------
.card-media {
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	background-color: #000;
	.button-video {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		.icon-wrapper {
			&:before {

			}
			.icon {

			}
		}

	}
	.image {
		padding-top: 122.37%;
		flex-grow: 1;
		&:after {
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
		}
	}
	.card-texts {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
	h3 {
		color: #FFF;
		font-size: 1rem;
	}
	.simple-cta {
		color: #FFF;
		.icon {
			fill: #FFF;
		}
	}
	&.card-media-16-9,
	&.card-media-wide {
		.button-video {
			font-weight: 600;
			.icon-wrapper {
				width: 40px;
				height: 40px;
				&:before {
					width: 40px;
					height: 40px;
				}
			}
			.icon {
				width: 18px;
				height: 18px;
			}
		}
	}
	&.card-media-wide {
		.image {
			padding-top: 56.27%;
		}
	}
	&.card-media-16-9 {
		.image {
			padding-top: 70%
		}
	}
	&.card-rounded {
		border-top-left-radius: 1000px;
		border-top-right-radius: 1000px;
		.image {
			padding-top: 116.20%
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.card-media {
		h3 {
			font-size: 0.889rem;
		}
		.image {
			padding-top: 157.58%;
		}
		&.card-media-wide {
			.image {
				padding-top: 34.07%
			}
		}
		.button-video {
			.icon-wrapper {
				&.me-lg-4 {
					margin-right: 8px !important;
				}
			}

		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.card-media {
		.button-video {
			&:hover{
				&:before {
					transform: scale(1);
				}
			}
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
