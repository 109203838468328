//Mobile------------------------------------------------------------------------------------------------------
.carousel-card-3-horizontal {

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}

@include media-breakpoint-down(lg) {
	.carousel-card-3-horizontal {
		.splide-carousel{
			margin-left: -12px;
			margin-right: -12px;
			.splide__slide {
				transition: all 0.3s;
			}
			.splide__slide:not(.is-active) {
				opacity: 0.3;
			}
		}
	}
}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.carousel-card-3-horizontal {
		.splide-carousel{
			.splide__pagination {
				display: none;
			}
			.splide__track {
				margin: 0 0;
			}
			.splide__arrows {
				margin-left: -20px;
				margin-right: -20px;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
