//Mobile------------------------------------------------------------------------------------------------------
.button-video {
	background: transparent;
	border: none;
	color: #FFF;
	font-weight: 700;
	.icon-wrapper {
		width: 80px;
		height: 80px;
		background: rgba(255, 255, 255, 0.65);
		border-radius: 80px;
		border: none;
		position: relative;
		&:before {
			content: '';
			display: block;
			width: 80px;
			height: 80px;
			border-radius: 80px;
			background: #FFF;
			transform: scale(0.7);
			transform-origin: center;
			position: absolute;
			top: 0;
			left: 0;
			transition: all 0.3s;
		}
		.icon {
			fill: #1A1A1A;
			position: relative;
			width: 36px;
			height: 36px;
		}
	}
	&.break-label {
		display: block !important;
		.icon {
			margin-right: 0 !important;
		}
	}
}
.close-video, .open-description-js {
	background: transparent;
	border: none;
	color: #FFF;
	font-weight:600;
	font-size: 0.8rem;
	transition: all 0.3s;
	.icon {
		fill: #FFF;
	}
	&.hidden {
		opacity: 0;
		pointer-events: none;
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.button-video {
		&:hover{
			.icon-wrapper {
				&:before {
					transform: scale(1);
				}
			}
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
