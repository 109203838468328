//Mobile------------------------------------------------------------------------------------------------------
.video-wrapper {
	.acceptoverlay.acceptoverlay-primary {
		background-color: var(--dark-blue);
		opacity: 1;
	}
	.vjs-transcription.accordion {
		--bs-accordion-bg : transparent;
		.accordion-header .accordion-button {
			background-color: var(--feedback-blue);
			color: #FFF;
			text-decoration: none;
			.icon {
				fill: #FFF;
				transition: all 0.3s;
			}
			&:after {
				display: none;
			}
			&[aria-expanded="true"] {
				.icon {
					transform: rotate(180deg);
				}
			}
 		}
		.accordion-body {
			background-color: var(--feedback-blue);
			color: #FFF;
		}
	}
}
.modal.video-modal {
	.modal-content {
		background-color: transparent;
	}
}
.vjs-theme-bootstrap-italia .vjs-control-bar {
	background-color: var(--dark-blue);
}

.vjs-theme-bootstrap-italia .vjs-big-play-button {
	background-color: var(--feedback-blue);
	border-radius: 4px;
	border: 2px solid #fff;
}

.video-js:hover {
	.vjs-theme-bootstrap-italia .vjs-big-play-button {
		background-color: var(--color-primary);
	}
}


//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.modal.video-modal {
		.modal-dialog {
			max-width: 80%;
			overflow: hidden;
			//display: flex;
			//flex-direction: column;
			//justify-content: center;
			margin-top: 0;
			margin-bottom: 0;
			//height: 100%;
		}

	}
	.video-wrapper {
		.transcription {
			position: relative;
			max-height: 100%;
			flex-shrink: 0;
			overflow: hidden;
			transition: all 0.3s;
			width: 320px;
			margin-right: -320px;
			&.opened {
				margin-right: 0;
			}
		}
		.transcription-head {
			padding-right: 24px;
			h3 {
				font-size: 1rem;
				color: #FFF;
				font-weight: 600;
				margin: 0;
			}
			button {
				background-color: transparent;
				border: none;
				.icon {
					fill: #FFF;
				}
			}
		}
		.transcription-content {
			padding: 24px;
			padding-right: 0;
			background-color: var(--feedback-blue);
			color: #FFF;
			width: 320px;
		}
		.transcription-scroller {
			position: relative;
			flex-grow: 1;
			overflow: hidden;
			overflow-y: auto;

		}
		.transcription-scroller-content {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			padding-right: 24px;

		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
