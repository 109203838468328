//Mobile------------------------------------------------------------------------------------------------------
.modal {
	z-index: 100010;
	.close-modal {
		.btn {
			color: var(--color-primary);
			font-size: var(--header-nav-close-label-size);
			font-weight: 700;
			padding-right: 0;
		}
		.icon-close-wrapper {
			width: var(--header-nav-close-icon-wrapper-size);
			height: var(--header-nav-close-icon-wrapper-size);
			border-radius: var(--header-nav-close-icon-wrapper-size);
			border: 1px solid var(--color-primary);
		}
		.icon {
			fill: var(--color-primary);
			width: var(--header-nav-close-icon-size);
			height: var(--header-nav-close-icon-size);
		}
	}
}
.modal-backdrop.show {
	z-index: 100005;
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//mobile only ---------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {
	.modal {
		.modal-dialog {
			margin: 24px;
		}
	}
}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.modal {
		.close-modal {
			position: absolute;
			width: 100%;
			right: 48px;
			margin-top: 16px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
