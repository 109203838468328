h2{
	&.h2-default {
		font-size: 1.5rem;
		color: var(--dark-blue);
	}
}
h3 {
	font-size: 1.25rem;
	&.h2-default {
		font-size: 1.5rem;
		color: var(--dark-blue);
	}
}


@include media-breakpoint-up(lg) {
	h2{
		&.h2-default {
			font-size: 1.7777777778rem;
		}
	}
	h3 {
		&.h2-default {
			font-size: 1.7777777778rem;
		}
		font-size: 1.3333333333rem;
		font-weight: 600;
		color: var(--text-color);
		&.title-small {
			font-size: 1.11111111rem;
		}
	}
}
