//Mobile------------------------------------------------------------------------------------------------------
.hero-splide {
	.breadcrumb-container {
		padding-top: 28px;
		padding-bottom: 34px;
	}
	.top-splide {
		margin-bottom: 40px;
		.splide-carousel .splide__pagination {
			display: none;
		}
		.splide__sr {
			display: none;
		}
	}
	.bottom-splide {
		.image {
			padding-top: 146.07%;
			border-radius: 12px;
			border: 3px solid transparent;
			overflow: hidden;
			transition: all 0.3s;
		}
		.splide__slide {
			&.is-active {
				.image {
					border-color: #FFF;
				}
			}
		}


	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}
@include media-breakpoint-down(md) {
	.hero-splide {
		.bottom-splide {
			.splide__slide {

				&.is-prev {
					.image {
						transform: scale(0.8);
						transform-origin: right center;
					}
				}
				&.is-next {
					.image {
						transform: scale(0.8);
						transform-origin: left center;
					}
				}

			}
		}
	}
}
@include media-breakpoint-down(lg) {
	.hero-splide {
		.bottom-splide {
			margin-left: -12px;
			margin-right: -12px;
		}
	}
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.hero-splide {
		.top-splide{
			margin-bottom: 24px;
			.splide-carousel .splide__track {
				margin: 0;
			}
			.splide__arrows {
				margin-left: -20px;
				margin-right: -20px;
			}

		}
		.card-big {
			min-height: 100%;
			display: flex;
		}
		.bottom-splide {
			.splide__arrows {
				margin-left: -20px;
				margin-right: -20px;
			}

			.splide-carousel {
				border: 1px solid #939596;
				border-radius: 8px;
				padding: 16px 32px;
				box-sizing: border-box;
				.splide__track {
					margin: 0;
				}
				.splide__arrows {
					margin-top: 0;
				}
				.splide__slide {
					width: 88px;
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
