//Mobile------------------------------------------------------------------------------------------------------
.card-big {

	.image {
		padding-top: 122.32%;
		min-height: 100%;
		border-radius: 8px;
		overflow: hidden;
	}
	.pill,h2 {
		margin-bottom: 12px;
	}

}
.share-button {
	background-color: transparent;
	border: none;
	color: #FFF;
	font-size: 0.889rem;
	font-weight: 700;
	.icon {
		fill: #FFF;
		width: 12px;
		height: 12px;
	}
}
.drop-wrapper {
	.dropdown-menu {
		background-color: var(--feedback-blue);
		color: #FFF;
		.icon {
			fill: #FFF;
		}
		span {
			color: #FFF;
			font-weight: 600;
		}
		a:hover {
			text-decoration: none;
			.icon {
				fill: #FFF !important;
			}
			span {
				color: #FFF !important;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.card-big {
		.card-big-texts {
			background-color: #0A1A23;
			padding: 56px;
			border-radius: 8px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		h2 {
			margin-bottom: 40px;
		}
		.pill {
			margin-bottom: 28px;
		}
	}

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
