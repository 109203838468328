//Mobile------------------------------------------------------------------------------------------------------
.back-to-top {
	border: var(--header-sublinks-border);
	background-color: #fff;
	color: var(--color-primary);
	font-weight: 600;

	font-size: 14px;

	border-radius: calc(var(--button-radius) * 2);
	box-shadow: 0px 79px 31px rgba(130, 168, 192, 0.01), 0px 44px 27px rgba(130, 168, 192, 0.03), 0px 20px 20px rgba(130, 168, 192, 0.05), 0px 5px 11px rgba(130, 168, 192, 0.06), 0px 0px 0px rgba(130, 168, 192, 0.06);
	.icon {
		top: -1px;
		stroke: var(--color-primary);
		transition: all 0.2s ease-out;
	}
	&:hover {
		color: #fff;
		border-color: var(--color-primary);
		.icon {
			stroke: #fff;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.back-to-top {
		padding: 12px 8px 8px;
		height: auto;
		&:hover {
			.icon {
				transform: translateY(-4px);
			}
		}
	}
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.back-to-top  {

	}
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.back-to-top {

	}
}
