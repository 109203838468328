//Mobile------------------------------------------------------------------------------------------------------
.contacts {
	.content {
		background-color: var(--crosslink-background);
		p {
			line-height: 1.3333333333333333;
		}
		.icon {
			fill: var(--map-icons);
			width: 24px;
			height: 24px;
		}
	}
	.inner {
		a {
			color: var(--bs-body-color);
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.bottom {
		background-color: var(--contacts-cta-bg);
		h3 {
			color: #fff;
			font-size: 1rem;
		}
		.simple-cta {
			color: #fff;
			.icon {
				fill: #fff;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
