//Mobile------------------------------------------------------------------------------------------------------
.image {
	position: relative;
	padding-top: 90%;
	img {
		position: absolute;
		inset: 0;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	&.gradient {
		&:after {
			content: '';
			display: block;
			position: absolute;
			inset: 0;
		}
	}
	&.overlay {
		&:after {
			content: '';
			display: block;
			position: absolute;
			inset: 0;
			background-color: #002D64;
			opacity: 0.2;
		}
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.image {
		padding-top: 49.33%
	}
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
}
