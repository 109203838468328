//Mobile------------------------------------------------------------------------------------------------------
.dropdown-panel {
	position: relative;
	.changer {
		border: none;
		padding: 16px;
		border-radius: 4px;
		border: 1px solid var(--color-primary);
		width: 100%;
		transition: all 0.3s;
		white-space: normal;
		background-color: #FFF;
		&[aria-expanded="true"] {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-bottom-color: #FFF;
			.icon {
				transform: rotate(180deg);
			}
		}
		&:after {
			display: none;
		}
	}
	.dropdown-menu {
		margin-top: -2px !important;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border: 1px solid var(--color-primary);
		border-top: 0;
		padding: 0 16px;
		width: 100%;
		z-index: 100000;
		.dropdown-item {
			white-space: normal;
			padding-left: 0;
			padding-right: 0;
			color: var(--color-primary);
			font-weight: 600;
		}
	}
	.icon {
		fill: var(--color-primary);
		transition: all 0.3s;
	}
	.button-label {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-align: left;
		font-weight: 600;
		color: var(--color-primary);
	}
	.cta-label {
		font-weight: 700;
		color: var(--color-primary);
		font-size: 0.778rem;
	}
	.collapse-content {
		background-color: #FFF;ì
		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
		}
		a {
			text-decoration: none;
			display: block;
			font-weight: 600;
			font-size: 1rem;
			color: var(--color-primary);
			padding: 16px 0;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.dropdown-panel {
		.changer {
			width: 100%;
		}
		.button-label {
			font-size: 1.111rem;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
