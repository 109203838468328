//Mobile------------------------------------------------------------------------------------------------------
.info {
	padding: 24px 16px;
	background-color: var(--pill-light);
	border-radius: 4px;
	.info-line {
		background-color: #0064DF;
		width: 8px;
		border-radius: 90px;
		flex-shrink: 0;
		margin-right: 26px;
	}
	h4,
	h3 {
		font-size: 1.333rem;
	}
	.icon {
		width: 26px;
		height: 26px;
		fill: #0064DF;
		margin-right: 10px;
		flex-shrink: 0;

	}
	p {
		a {
			font-weight: 600;
			color: var(--text-color);
			text-decoration: none;
			word-break: break-all;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&.simple {
		p {
			a {
				color: var(--color-primary);
				text-decoration: underline;
				word-break: normal;
			}
		}
	}
}
.infos {
	p {
		margin: 0;
		a {
			color: var(--text-color);
			text-decoration: none;
			word-break: break-all;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.icon {
		fill: var(--color-primary);
		margin-right: 20px;
	}
	.info-block:not(:last-child) {
		margin-bottom: 24px;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.info {
		.icon {
			margin-top: 4px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
