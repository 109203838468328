//Mobile------------------------------------------------------------------------------------------------------
.carousel-card-2 {
	.splide-carousel .splide__arrows {
		display: none !important;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-down(lg) {
 .carousel-card-2 {

		.carousel-zone {
			//margin-left: calc((24px *.5)*-1);
			//margin-right: calc((24px *.5)*-1);
		}

 	}
}
@include media-breakpoint-up(lg) {
	.carousel-card-2 {

		.carousel-zone {
			margin-bottom: -210px;
		}

 	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
