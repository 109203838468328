//Mobile------------------------------------------------------------------------------------------------------
.breadcrumb-container {
	position: relative;
	z-index: 1;
	.breadcrumb {
		padding: 0;
		margin: 0;
		.breadcrumb-item{
			font-size: 0.875rem;
			a {
				color: #FFF;
				font-weight: 700;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			&.active {
				color: #FFF;
			}
			span.separator {
				color: #FFF;
				font-weight: 700;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}

@include media-breakpoint-down(lg) {
	.breadcrumb-container {
		white-space: nowrap;
		.breadcrumb {
			padding-bottom: 16px;
		}
		ol {
			white-space: nowrap;
			flex-wrap: nowrap;
			overflow-y: auto;
		}
	}
}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.breadcrumb-container {
		.breadcrumb {
			.breadcrumb-item{
				font-size: 0.889rem;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
