//Mobile------------------------------------------------------------------------------------------------------
.card-horizontal {
	border-radius: 4px;
	overflow: hidden;
	filter: drop-shadow(0px 79px 31px rgba(130, 168, 192, 0.01)) drop-shadow(0px 44px 27px rgba(130, 168, 192, 0.03)) drop-shadow(0px 20px 20px rgba(130, 168, 192, 0.05)) drop-shadow(0px 5px 11px rgba(130, 168, 192, 0.06)) drop-shadow(0px 0px 0px rgba(130, 168, 192, 0.06));
	border: 1px solid var(--card-border);
	.image {
		padding-top: 37.32%;
	}
	.card-main {
		padding: 16px;
		background-color: #FFF;
	}
	h3 {
		a{
			text-decoration: none;
			color: var(--dark-blue);
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-weight: 600;
		}
	}
	p {
		font-size: 1rem;
		color: var(--light-text);
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.card-horizontal {
		.image-zone {
			width: 50%;
			flex-shrink: 0;
			min-height: 100%;
		}
		.card-main {
			padding: 24px 24px 24px 32px;
			flex-grow: 1;
		}
		.image {
			padding-top: 0;
			min-height: 100%;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.card-horizontal {
		.card-main {
			position: relative;
		}
		.bg-icon {
			width: 50px;
			height: 50px;
			fill:  rgba(0, 95, 115, 0.08);
			position: absolute;
			left: -10px;
			bottom: -20px;
		}
		p {
			font-size: 0.889rem;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
