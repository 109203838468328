//Mobile------------------------------------------------------------------------------------------------------
.card-podcast {
	border-radius: 4px;
	background-color: var(--footer-dark-blue);
	h3 {
		font-size: 1.25rem;
	}
	.card-podcast-image {
		max-width: 152px;
		width: 40%;
		flex-shrink: 0;
		border-radius: 4px;
		overflow: hidden;
		.image {
			padding-top: 100%;
		}
	}
	.simple-cta {
		color: #FFF;
		.icon {
			fill: #FFF;
		}
	}
	&.green {
		background-color: #07736F;
	}
	&.brown {
		background-color: #72512B;
	}
	&.blue {
		background-color: #1C3256;
	}
	&.grey {
		background-color: #516783;
	}
	&.yellow {
		background-color: #B48136;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.card-podcast {
		h3 {
			font-size: 1.111rem;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
