//Mobile------------------------------------------------------------------------------------------------------
.btn {
	border-radius: var(--button-radius);
	display: flex;
	align-items: center;
	&.btn-primary {
		background-color: var(--color-primary);
		.icon {
			fill: #FFF;
		}
		&:focus {
			color: #fff;
		}
	}
	&.btn-negative {
		background-color: #FFF;
		color: var(--button-negative-color);
		font-weight: 700;
		.icon {
			fill: var(--button-negative-color);
		}
		&.black-text {
			&:not(:hover) {
				color: #1A1A1A;
				.icon {
					fill: #1A1A1A;
				}
			}
		}
	}
	&.btn-outline-primary {
		background-color: #FFF;
		color: var(--color-primary);
		font-weight: 700;
		border: 2px solid var(--color-primary);
		box-shadow: none;
		.icon {
			fill: var(--color-primary);
		}
	}
	&.btn-white {
		color: var(--text-color);
		font-weight: 700;
		background-color: #fff;
		.icon {
			fill: var(--text-color-text);
		}
	}
	&.btn-small {
		padding:8px 24px;
		border-radius: 4px;
	}

	&.btn-xs {
		padding:6px 24px;
		border-radius: 4px;
		font-size: 0.889rem;
	}
	&.js-close-search-panel {
		color: #FFF;
		.icon {
			fill: #FFF;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.btn {
		&.btn-large {
			padding:16px 32px;
			font-size: 1rem;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.btn {
		&.btn-negative {
			&:hover {
				background-color: var(--color-primary);
				color: #FFF;
				.icon {
					fill: #FFF;
				}
			}
		}

	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.btn {
		.icon {
			transition: all 0.3s;
		}
	}
}
