//Mobile------------------------------------------------------------------------------------------------------
.hero-video {
	position: relative;
	padding-bottom: 114px;
	margin-bottom: 124px;
	color: var(--text-color);
	.breadcrumb-container {
		padding-top: 28px;
		padding-bottom: 34px;
	}
	.carousel-card-2 {
		margin-top: 32px;

	}
	.carousel-zone {
		margin-bottom: -224px;
	}
	.splide-carousel .splide__pagination__page {
		background-color: #FFF;
		&.is-active {
			background-color: transparent;
		}
	}
	.carousel-zone {

	}
	.image {
			position: absolute;
			inset: 0;
			top: 0;
			left: 0;
			padding-top: 0;
			&.gradient {
				&:after {
					background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50.38%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44267) 37.31%, rgba(0, 0, 0, 0.6) 100%), ;
				}
			}
		}
	.text-zone {
		position: relative;
	}
	.button-video {
		margin-bottom: 40px;
		.icon-wrapper {
			width: 40px;
			height: 40px;
			.icon {
				width: 18px;
				height: 18px;
			}
			&:before {
				width: 40px;
				height: 40px;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.hero-video {
		padding-bottom: 104px;
		margin-bottom: 100px;
		.breadcrumb-container {
			padding-top: 88px;
			padding-bottom: 28px;
		}
		.splide-carousel .splide__track {
			margin: 0 48px;
		}
		.text-zone {
			margin-top: 76px;
			max-width: 40%;
			margin-left: 24px;
		}
		.carousel-zone {
			margin-bottom: -200px;
		}
	  .button-video {
			margin-top: 48px;
			.icon-wrapper {
				width: 72px;
				height: 72px;
				.icon {
					width: 32px;
					height: 32px;
				}
				&:before {
					width: 72px;
					height: 72px;
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
