//Mobile------------------------------------------------------------------------------------------------------
.footnotes {
	p,
	ol,
	ul {
		font-size: 0.8888888888888888rem;
		color: var(--light-text);
		font-style: italic;
		margin-bottom: 0;
	}
	ol,
	ul {
		padding-left: 1.1em;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.footnotes {
		p,
		ol,
		ul {
			//color: var(--color-dida);
		}
	}

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
