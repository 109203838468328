//Mobile------------------------------------------------------------------------------------------------------
.feedback {
	background-color: var(--feedback-blue);
	button {
		font-size: 1rem;
		line-height: 1.3;
		&:hover,
		&:active {
			text-decoration: underline;
		}
	}

	label,
	legend,
	.footnote {
		font-size: 0.8888888888888888rem;
	}

	label,
	legend {
		font-weight: bold;
		line-height: 1.25;
	}

	legend {
		overflow: visible;
		text-overflow: initial;
		white-space: normal;
	}

	.score {
		li {
			width: 48px;
			line-height: 1.2;
			text-align: center;
			margin-right: 1rem;
			position: relative;
			[type=radio] {
				width: 46px;
				height: 46px;
				margin-bottom: 6px;
				&:hover {
					cursor: pointer;
				}
				&+label::before {
					content: attr(data-number);
					position: absolute;
					left: -1px;
					top: -1px;
					width: 48px;
					height: 48px;
					z-index: 0;
					border-radius: 50%;
					transition: .2s ease-out;
					background-color: #EDF4F7;
					color: #415559;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 1rem;
					font-weight: bold;
				}
				&:checked+label::before {
					background-color: #216FA3;
					color: #fff;
				}
			}
		}
		label {
			font-size: 0.6666666666666666rem;
			.centered {
				display: block;
				text-align: center;
				position: absolute;
				transform: translateX(-1rem);
			}
		}
	}

	textarea,
	input,
	select {
		font-weight: normal;
		font-size: 0.8888888888888888rem;
		border: 1px solid #939596;
		border-radius: var(--button-radius);
	}

	select {
		appearance: none;
		background-image: url('../assets/select-caret.svg');
		background-repeat: no-repeat;
		background-position: calc(100% - 16px) center;
	}

	.text-counter {
		font-size: 0.7777777777777778rem;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

//Mobile tablet only ---------------------------------------------------------------------------------------
@include media-breakpoint-down(lg) {
	.feedback {
		.score {
			li {
				width: 44px;
				margin-right: 0.75rem;
				[type=radio] {
					width: 42px;
					height: 42px;
					&+label::before {
						left: -1px;
						top: -1px;
						width: 44px;
						height: 44px;
					}
				}
			}
		}
	}
}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
