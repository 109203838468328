//Mobile------------------------------------------------------------------------------------------------------
.free-text {
	color: var(--light-text);
	blockquote {
		border-left-color: var(--light-text);
		font-weight: 300;
	}
	h2,
	.intro {
		color: var(--text-color);
	}
	h3 {
		font-size: 1.5555555555555556rem;
		color: var(--light-text);
	}
	.intro {
		font-size: 0.8888888888888888em;
		margin-bottom: 40px;
		font-weight: 400;
	}
	&.italic {
		.content {
			line-height: 1.7777777777777777;
			font-style: italic;
			blockquote,
			p {
				margin-bottom: 1.7777777777777777rem;
				a {
					color: #002D64;
				}
			}
		}
	}
	.text-big {
		font-size: 1.3333333333333333rem;
		line-height: 1.6666666666666667;
		font-style: italic;
	}
	blockquote {
		font-style: italic;
		line-height: 1.7777777777777777;
		em {
			font-size: 1.7777777777777777rem;
			line-height: 1.5;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
