//Mobile------------------------------------------------------------------------------------------------------
.carousel-podcast {
	.card-podcast:not(:last-child) {
		margin-bottom: 16px;
	}
	.splide-carousel .splide__arrows {
		display: none !important;
	}
	.splide-carousel .splide__track {
		margin: 0;
	}
	.splide-carousel {
		margin-left: -40px;
		.card-podcast:last-child {
			transform: translateX(-40px);
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.carousel-podcast {
		.card-podcast {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			min-height: 165px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
