//Mobile------------------------------------------------------------------------------------------------------
.map {
	.map-wrapper {
		position: relative;
		overflow: hidden;
		iframe {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}
	.info {
		background-color: var(--blue-light);
	}
	.content {
		h3 {
			font-size: 1.3333333333333333rem;
		}
		p {
			line-height: 1.3333333333333333;
		}
		.icon {
			fill: var(--map-icons);
			width: 24px;
			height: 24px;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {
	.map {
		.map-wrapper {
			margin: 0 calc((var(--bs-gutter-x)*.5) * -1);
			width: calc(100% + var(--bs-gutter-x));
			min-height: 300px;
		}
	}
}



//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.map {
		.content {
			margin: 0 calc(var(--bs-gutter-x)*.5);
			padding: 0;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
