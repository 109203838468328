//Mobile------------------------------------------------------------------------------------------------------
.carousel-cards-3 {
	&.splide {
		visibility: visible;
	}

	.card-news,
	.card-icon {
		filter: drop-shadow(0px 0px 6px rgba(130, 168, 192, 0.3));
	}

	.splide__slide {
		padding: 16px 0;
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}
@include media-breakpoint-down(lg) {
	.carousel-cards-3 {
		.carousel-zone {
			margin-left: calc((24px *.5)*-1);
			margin-right: calc((24px *.5)*-1);
		}
	}

}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.carousel-cards-3-content{
		padding-left: var(--default-x-padding-desk);
		padding-right: var(--default-x-padding-desk);
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
