//Mobile------------------------------------------------------------------------------------------------------
.it-footer {
	.it-footer-main {
		background-color: var(--footer-blue);
	}
	.it-brand-wrapper {
		img {
			width: 100%;
		}
	}
	.it-footer-small-prints {
		background-color: var(--footer-dark-blue);
		a {
			font-weight: 700;
			text-decoration: underline;
		}
	}
	#footer-title {
		color: #fff;
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-down(sm) {
	.it-footer {
		.it-footer-small-prints {
			.list-inline-item {
				width: calc(50% - 1rem);
			}
		}
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.it-footer-main {
		.h4 {
			a {
				display: block;
			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
