//Mobile------------------------------------------------------------------------------------------------------
.filters {
	.btn-filter {
		border: 2px solid var(--color-primary);
	}
}
.modal.filter-panel-wrapper {
	.modal-dialog {
		height: 100%;
		padding: 0;
		margin: 0;
		width: 100%;
		max-width: inherit;
		transform: translateX(100%);
	}

	.filter-group {
		background-color:var(--blue-light);
		border-radius: 4px;
	}
	.filter-button {
		background-color: transparent;
		border: none;
		font-size: 1.111rem;
		font-weight: 600;
		color: var(--color-primary);
		.icon {
			fill: var(--color-primary);
			transition: all 0.3s;
		}
		&[aria-expanded="true"] {
			.icon {
				transform: rotate(180deg);
			}
		}
	}
	.search-wrapper {
		background-color: #FFF;
		border: 1px solid #939596;
		border-radius: 4px;
		input {
			background-color: transparent;
			border: none;
			padding: 0;
			&::placeholder {
				font-weight: 400;
			}
		}
		.icon {
			fill: #415559;
			margin: 0 10px;
		}
	}
	.field {
		border-bottom: 1px solid #939596;
		input {
			border-color: var(--text-color);
			margin: 0;
		}
	}
	.modal-content {
		width: 100%;
		height: 100%;
		padding: 16px;
	}
	.modal-scroller {
		position: relative;
	}
	.modal-scroll-content {
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
		position: absolute;
		width: 100%;
		left: 0;
		top: 0;
	}
	.modal-main-wrapper {
		height: 100%;
	}
	.modal-head {
		button {
			background-color: transparent;
			border: none;
			color: var(--color-primary);
			font-weight: 700;
			.icon {
				fill: var(--color-primary);
			}
		}
	}
	.modal-filter-footer {
		p {
			font-weight: 600;
		}
		.btn-primary {
			border: 2px solid var(--color-primary);
		}
	}
	&.show {
		.modal-dialog {
			transform: translateX(0)
		}
		h2 {
			font-size: 1.556rem;
			margin: 0;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.filters {
		.btn-filter {
			border: 1px solid #E9F0F5;
		}
	}
	.modal.filter-panel-wrapper {
		.modal-dialog {
			height: 100%;
			padding: 0;
			margin: 0;
			margin-left: calc(100% - 470px);
			width: 470px;
			max-width: inherit;
			transform: translateX(100%);
		}
		.modal-content {
			padding: 24px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
