//Mobile------------------------------------------------------------------------------------------------------

//-mobile only
@include media-breakpoint-down(md) {

}
@include media-breakpoint-down(lg) {

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.sticky-header {
		.it-header-center-wrapper {
			height: 0;
			padding-top: 0;
			.it-brand-wrapper {
				a {
					visibility: hidden;
					transition: visibility;
					transition-delay: 0.3s;
					pointer-events: none;
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
