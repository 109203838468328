//Mobile------------------------------------------------------------------------------------------------------
.banner {
	overflow: hidden;
	.pill-zone {
		position: relative;
	}
	.btn {
		position: relative;
	}
	.banner-main {
		padding: 28px 24px;
		position: relative;
		overflow: hidden;
		background-color: var(--banner-background);
		&.branded {
			&:after {
				content: '';
				display: block;
				position: absolute;
				inset: 0;
				background: url('../assets/big-circle.svg') no-repeat;
				background-size: 477px;
				background-position: -190px 120px;
				pointer-events: none;
			}
		}
		&.banner-question {
			&:after {
				content: '';
				display: block;
				position: absolute;
				inset: 0;
				background: url('../assets/Question-circle.svg') no-repeat;
				background-size: 93px;
				background-position: 0px -20px;
				pointer-events: none;
			}
		}
	}
	h2 {
		font-size: 1.5rem;
	}
	.banner-text {
		width: 65%;
		position: relative;
		z-index: 1;
		color: #FFF;
	}
	.image {
		position: absolute;
		inset: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-top: 0;
		img {
			object-position: right;
		}
		&.gradient {
			&:after {
				background: linear-gradient(94.05deg, rgba(11, 20, 29, 0) -0.62%, rgba(11, 20, 29, 0.81) 31.15%, #0B141D 62.43%);
				transform: rotate(-180deg);
			}
			&.gradient-primary {
				&:after {
					background: linear-gradient(89.41deg, rgba(2, 47, 102, 0) 0.46%, rgba(2, 47, 102, 0.81) 42.68%, #033067 57.87%);
					transform: rotate(-180deg);
				}
			}
		}
	}
	&.banner-small {
		.banner-text {
			width: auto;
		}
		.banner-main {
			padding: 16px;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}

@include media-breakpoint-down(lg) {
	.banner {
		.banner-main.banner-question {
			.image {
				background-color: #002D64;
				&::after {
					display: none;
				}
			}
			img {
				display: none;
			}
		}
	}
	.sidebar-content {
		.banner {
			margin-left: calc((var(--bs-gutter-x) / 1.333333) * -1);
			margin-right: calc((var(--bs-gutter-x) / 1.33333) * -1);
		}
	}
}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

	.sidebar-content {
		.nopad-xl {
			padding: 0;
		}
	}
	.banner {
		h2 {
			font-size: 1.778rem;
		}
		.banner-main {
			padding: 40px;
			border-radius: 4px;
			&.branded {
				&:after {
					background-size: 477px;
					background-position: -200px 140px;
				}
			}
		}
		.banner-text {
			width: 35%;
			&.large-text {
				width: 50%;
			}
		}
		&.banner-small {
			.banner-text {
				margin-right: 24px;
				&.large-text {
					width: auto;
					p {
						margin-bottom: 0;
					}
				}
			}
			.banner-main {
				padding: 40px 48px;
				&.banner-question {
					padding-left: 88px;
					&:after {
						background-position: 0px 50%;
					}
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
