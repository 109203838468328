//Mobile------------------------------------------------------------------------------------------------------
.it-header-navbar-wrapper {
	position: relative;
	left: auto;
	top: auto;
	margin-top: 0;
	.custom-navbar-toggler{
		span {
			font-size: var(--header-nav-burger-font-size);
			font-weight: 700;

		}
	}
	.icon-wrapper {
		width: var(--header-nav-burger-size);
		height: var(--header-nav-burger-size);
		border-radius: var(--header-nav-burger-size);
		background-color: var(--color-primary);
		.icon {
			flex-shrink: 0;
			fill: var(--header-nav-burger-color);
		}
	}
	.navbar {
		.navbar-collapsable {
			.navbar-nav {
				li {
					.nav-link {
						border: none;
						background: transparent;
						font-weight: 400;
						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
	.support {
		background-color: var(--button-primary-bg);
		border-radius: var(--button-radius);
		padding: var(--button-padding);
		color:var(--button-primary-color);
		text-decoration: none;
		.icon {
			fill: var(--button-primary-color);
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}
//Under Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-down(lg) {
	.it-header-navbar-wrapper {
		.custom-navbar-toggler {
			span {
				color: var(--color-primary);
			}
		}
		.navbar {
			.menu-secondary {
				border-bottom: var(--header-sublinks-border);
				.link-list-wrapper {
					padding: 0;
					padding-top: 24px;
					.link-list{
						li {
							a {
								font-size: var(--header-nav-sublink-size);
								padding: 0;
								line-height: inherit;
								white-space: break-spaces;
								span {
									color: var(--color-primary);
								}
								&.section-link {
									font-weight: 700;
								}
								.icon {
									fill: var(--color-primary);
								}
							}
							&:not(:last-child) {
								margin-bottom: 24px;
							}
						}
					}
				}
			}
			.navbar-collapsable .navbar-nav li {
				.nav-link {
					font-size: var(--header-nav-link-size);
					border-bottom: var(--header-nav-link-border);
					padding: 24px 8px;
					color: var(--color-primary);
					text-decoration: none;
					&:hover {
						text-decoration: none;
						&:not(.active) {
							text-decoration: none;
						}
					}
					&[aria-expanded="true"] {
						.icon {
							transform: rotate(180deg);
						}
					}
					.icon {
						fill: var(--color-primary);
						transition: all 0.3s;
					}
					&.active {
						border-left: none;
						font-weight: 700;
					}
				}
			}

			.close-div {
				position: fixed;
				left: 0;
				right: 0;
				top: 0;
				padding: 24px 16px;
				background-color: #FFF;
				.close-menu {
					width: auto;
				}
				h2 {
					font-size: var(--header-nav-heading-size);
				}
				button {
					font-size: var(--header-nav-close-label-size);
					font-weight: 700;
					.icon-close-wrapper {
						width: var(--header-nav-close-icon-wrapper-size);
						height: var(--header-nav-close-icon-wrapper-size);
						border-radius: var(--header-nav-close-icon-wrapper-size);
						border: 1px solid var(--color-primary);
					}
					.icon {
						fill: var(--color-primary);
						width: var(--header-nav-close-icon-size);
						height: var(--header-nav-close-icon-size);
					}
				}
			}
			.navbar-collapsable {
				.menu-wrapper {
					right: 0;
					top: 64px;
					padding: 24px 16px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.navbar-nav {
						overflow: inherit;
						flex-shrink: 0;
					}
				}
			}
		}
	}
	.transparent-header {
		.it-header-navbar-wrapper .icon-wrapper {
			background-color: var(--header-nav-burger-color);
			.icon {
				fill: var(--color-primary);
			}
		}
		.it-header-navbar-wrapper .custom-navbar-toggler span {
			color: #FFF;
		}
	}

}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	//-versione trasparente
	.transparent-header {
		.it-header-navbar-wrapper {
			background-color: transparent;
		}
	}
	.transparent-nav {
		&.sticky-header {
			.it-header-navbar-wrapper {
				background-color: var(--header-nav-default-color);
			}
		}
		.it-header-navbar-wrapper {
			background-color: transparent;
		}
	}
	.it-header-navbar-wrapper {
		background-color: var(--header-nav-default-color);
		.navbar {
			.navbar-collapsable {
				.navbar-nav {
					.nav-item:not(:last-child) {
						margin-right: 32px;
					}
					li {
						.nav-link {
							color: var(--header-nav-link-desk-color);
							font-weight: 600;
							border-bottom: 2px solid transparent !important;
							padding-left: 0;
							padding-right: 0;
							&[aria-expanded="true"],
							&.active {
								border-bottom: 2px solid var(--header-nav-link-desk-color) !important;
							}
							&.active {
								font-weight: 700;
							}
							&:hover:not(.active) {
								text-decoration: none;
							}
							&.active {
								text-decoration: none;
							}
						}
					}
				}
			}
		}
		nav {
			.navbar-collapsable {
				.menu-wrapper {
					padding-left: var(--default-x-padding-desk);
					padding-right: var(--default-x-padding-desk);
					.navbar-nav {
						.dropdown-menu {
							border-radius: var(--dropdown-border-radius);
							box-shadow: var(--dropdown-box-shadow);
							padding: var(--header-nav-dropdown-padding);
							box-sizing: border-box;
							width: var(--header-nav-dropdown-width);
							li:not(:last-child) {
								margin-bottom: 16px;
							}
							&:before {
								display: none;
							}
							a {
								white-space: break-spaces;
								&.section-link {
									font-weight: 700;
									border-top: var(--header-sublinks-border);
									padding-top: 24px;
									.icon {
										width: 17px;
										height: 17px;
									}
								}
								.icon {
									fill: var(--color-primary);
								}
							}
							&.show {
								top: calc(100% - 10px);
							}
						}
					}
				}
			}
		}
		.search-zone {
			opacity: 0;
			transition: all 0.3s;
			pointer-events: none;
			visibility: hidden;
		}
		.search-panel {
			padding-left: var(--default-x-padding-desk);
			padding-right: var(--default-x-padding-desk);
		}
	}
	.it-header-navbar-wrapper {
		&.white-nav {
			background-color: #FFF;
			.navbar {
				.navbar-collapsable {
					.navbar-nav {
						li {
							.nav-link {
								color: var(--color-primary);
								&[aria-expanded="true"]{
									border-bottom: 2px solid var(--color-primary) !important;
								}
								&.active {
									color: var(--dark-blue);
									border-bottom: 2px solid var(--dark-blue) !important;
								}
								&:hover:not(.active) {
									text-decoration: none;
								}
								&.active {
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.it-header-navbar-wrapper {
		transition: all 0.3s;
	}
}
