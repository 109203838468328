//Mobile------------------------------------------------------------------------------------------------------
.pagination {
	.page-item.disabled a.page-link {
		color: #415559;
	}
	.page-item a.page-link {
		color: var(--color-primary);
		font-weight: 700;
		background-color: #FFF;
		&.navig-arrow {
			background-color: transparent;
		}
	}
	.page-item .page-link[aria-current] {
		color: #0F3E75;
		border: 2px solid #0F3E75;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.pager {
		margin-left: var(--default-x-padding-desk);
		margin-right: var(--default-x-padding-desk);
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

	.pagination {
		.page-item:not([aria-current]) a.page-link:not(.navig-arrow){
				&:hover {
					background-color: var(--color-primary);
					border-color:var(--color-primary);
					color: #FFF;
				}
			}
		}
	}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
