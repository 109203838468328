//Mobile------------------------------------------------------------------------------------------------------
.modal-contact {
	input {
		width: 100%;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
