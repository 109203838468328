//Mobile------------------------------------------------------------------------------------------------------
@keyframes spinner-rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes spinner-ripple {
	0% {
		top: 50%;
		left: 50%;
		width: 0;
		height: 0;
		opacity: 0;
	}
	4.9% {
		top: 50%;
		left: 50%;
		width: 0;
		height: 0;
		opacity: 0;
	}
	5% {
		top: 50%;
		left: 50%;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}

@keyframes spinner-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes spinner-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes spinner-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(15px, 0);
	}
}

@keyframes spinner-facebook {
	0% {
		top: 4px;
		height: 32px;
	}
	50%,
	100% {
		top: 12px;
		height: 16px;
	}
}

@keyframes spinner-radius {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.spinner {

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.spinner {
		&:before {
			content: none;
		}
		&.double {
			&:after {
				content: "";
				display: block;
				width: var(--spinner-dimension);
				height: var(--spinner-dimension);
				border-radius: 50%;
				border: var(--spinner-border) solid var(--spinner-color);
				border-color: var(--spinner-color) transparent var(--spinner-color)
					transparent;
				animation: spinner-rotation 1.2s linear infinite;
			}
		}
		&.ring {
			div {
				box-sizing: border-box;
				display: block;
				position: absolute;
				width: var(--spinner-dimension);
				height: var(--spinner-dimension);
				border: var(--spinner-border) solid var(--spinner-color);
				border-radius: 50%;
				animation: spinner-rotation 1.2s cubic-bezier(0.5, 0, 0.5, 1)
					infinite;
				border-color: var(--spinner-color) transparent transparent
					transparent;
				&:nth-child(1) {
					animation-delay: -0.45s;
				}
				&:nth-child(2) {
					animation-delay: -0.3s;
				}
				&:nth-child(3) {
					animation-delay: -0.15s;
				}
			}
		}
		&.ripple {
			div {
				position: absolute;
				border: calc((var(--spinner-border)) / 2) solid var(--spinner-color);
				opacity: 1;
				border-radius: 50%;
				animation: spinner-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
				&:nth-child(2) {
					animation-delay: -0.5s;
				}
			}
		}
		&.ellipsis {
			div {
				position: absolute;
				top: 15px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: var(--spinner-color);
				animation-timing-function: cubic-bezier(0, 1, 1, 0);
				&:nth-child(1) {
					left: 0;
					animation: spinner-ellipsis1 0.6s infinite;
				}
				&:nth-child(2) {
					left: 0;
					animation: spinner-ellipsis2 0.6s infinite;
				}
				&:nth-child(3) {
					left: 15px;
					animation: spinner-ellipsis2 0.6s infinite;
				}
				&:nth-child(4) {
					left: 30px;
					animation: spinner-ellipsis3 0.6s infinite;
				}
			}
		}
		&.facebook {
			width: var(--spinner-dimension);
			height: var(--spinner-dimension);
			div {
				display: inline-block;
				position: absolute;
				left: 0;
				width: 10px;
				background: var(--spinner-color);
				animation: spinner-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1)
					infinite;
				&:nth-child(1) {
					left: 0px;
					animation-delay: -0.24s;
				}
				&:nth-child(2) {
					left: 15px;
					animation-delay: -0.12s;
				}
				&:nth-child(3) {
					left: 30px;
					animation-delay: 0;
				}
			}
		}
		&.radius {
			div {
				transform-origin: 20px 20px;
				animation: spinner-radius 1.2s linear infinite;
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 20px;
					width: 4px;
					height: 8px;
					border-radius: 20%;
					background: var(--spinner-color);
				}
				&:nth-child(1) {
					transform: rotate(0deg);
					animation-delay: -1.1s;
				}
				&:nth-child(2) {
					transform: rotate(30deg);
					animation-delay: -1s;
				}
				&:nth-child(3) {
					transform: rotate(60deg);
					animation-delay: -0.9s;
				}
				&:nth-child(4) {
					transform: rotate(90deg);
					animation-delay: -0.8s;
				}
				&:nth-child(5) {
					transform: rotate(120deg);
					animation-delay: -0.7s;
				}
				&:nth-child(6) {
					transform: rotate(150deg);
					animation-delay: -0.6s;
				}
				&:nth-child(7) {
					transform: rotate(180deg);
					animation-delay: -0.5s;
				}
				&:nth-child(8) {
					transform: rotate(210deg);
					animation-delay: -0.4s;
				}
				&:nth-child(9) {
					transform: rotate(240deg);
					animation-delay: -0.3s;
				}
				&:nth-child(10) {
					transform: rotate(270deg);
					animation-delay: -0.2s;
				}
				&:nth-child(11) {
					transform: rotate(300deg);
					animation-delay: -0.1s;
				}
				&:nth-child(12) {
					transform: rotate(330deg);
					animation-delay: 0s;
				}
			}
		}
	}
}
