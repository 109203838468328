//Mobile------------------------------------------------------------------------------------------------------

.search-panel-wrapper {
	position: absolute;
	inset: 0;
	background-color: var(--dark-blue);
	width: auto;
	height: auto;
	overflow-y: inherit;
	.modal-dialog {
		margin: 0;
		max-width: inherit;
		display: flex;
		height: 100%;
		align-items: center;
		.modal-content {
			background-color: transparent;
			box-shadow: none;
			display: block;
		}
	}
}
.search-panel {

	.search-panel-content {
		background-color: var(--search-panel-content-bg);
		border-radius: 4px;
		padding: 4px 8px;
		border: var(--search-panel-content-border);
	}
	.select-wrapper {
		height: 24px;
		border-radius: 24px;
		background-color: var(--blue-light);
		padding:6px 16px 6px 16px;
		select {
			font-size: 0.667rem;
			color: var(--color-primary);
			height: auto;
			border-bottom: none;
			height: 24px;
			padding: 0;
			background: transparent;
			padding-right: 8px;
		}
	}
	.input-wrapper {
		input {
			border: none;
			background: transparent;
			color:var(--text-color);
			font-weight: 400;
			height: auto;
			font-size: 0.889rem;
			padding: 2px 10px;
			&::placeholder {
				color:var(--text-color);
				font-weight: 400;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.search-panel{
		.search-cat {
			font-size: 0.6666666666666666rem;
			strong {
				font-weight: 700;
			}
		}

		.reset-search {
			.btn {
				color: var(--color-primary);
				font-size: var(--header-nav-close-label-size);
				font-weight: 700;
				padding: 6px 8px 5px 16px;
				font-size: 0.7777777777777778rem;
				border-left: 1px solid #999;
				border-radius: 0;
			}
			.icon-close-wrapper {
				width: var(--header-nav-close-icon-wrapper-size);
				height: var(--header-nav-close-icon-wrapper-size);
				border-radius: var(--header-nav-close-icon-wrapper-size);
				border: 1px solid var(--color-primary);
			}
			.icon {
				fill: var(--color-primary);
				width: var(--header-nav-close-icon-size);
				height: var(--header-nav-close-icon-size);
			}
		}
	}
}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-down(lg) {
	.search-panel{
		.btn.btn-xs[type="submit"] {
			padding:8px 26px;
		}
	}
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
