//Mobile------------------------------------------------------------------------------------------------------
.chip {
	border: var(--chip-border);
	font-weight: 700;
	background-color: var(--chip-color-bg);
	color: var(--chip-color);
	.chip-label {
		color: var(--chip-color);
	}
	em {
		font-style: normal;
	}
	&:not(.chip-disabled) {
		&:hover,
		&:active {
			background-color: var(--color-primary);
			border-color: var(--color-primary);
			box-shadow: none;
		}
	}
	&.active {
		background-color: var(--chip-active-color-bg);
		border-color: var(--chip-active-color-bg);
		color: var(--chip-active-color);
		.chip-label {
			color: var(--chip-active-color);
		}
		.on {
			display: block;
		}
		.off {
			display: none;
		}
	}
	.on {
		display: none;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
