//Mobile------------------------------------------------------------------------------------------------------
.search-media {
	.btn.btn-primary {
		background-color: #363636;
		&:hover {
			background-color: var(--color-primary);
		}
	}
	label {
		color: var(--text-color);
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.search-media {
		.btn.btn-primary {
			height: 40px;
		}
		.search-panel {
			.search-panel-content {
				padding: 8px;
			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
