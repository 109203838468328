//Mobile------------------------------------------------------------------------------------------------------
.splide-carousel {

	.splide__arrows {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		pointer-events: none;

	}
	.splide__arrow {
		width: 40px;
		height: 40px;
		background-color: var(--color-primary);
		border-radius: 4px;
		border: none;
		pointer-events: all;
		.icon {
			fill: #FFF;
		}
	}
	.splide__pagination {
		display: flex;
		align-items: center;
		margin-top: 24px;
		padding: 0;
		li {
			display: flex;
			align-items: center;
		}
	}
	.splide__pagination__page {
		width: 10px;
		height: 10px;
		border-radius: 9px;
		background-color: var(--carousel-active-color);
		border: none;
		padding: 0;
		margin:0 8px;
		transition: all 0.3s;
		transform-origin: center;
		position: relative;
		&:after {
			content: '';
			width: 16px;
			height: 16px;
			position: absolute;
			top: -3px;
			left: -3px;
			display: block;
			border: 3px solid transparent;
			border-radius: 14px;
			transition: all 0.3s;
			outline: none !important;
		}
		&.is-active {
			background-color: transparent;
			&:after {
				border: 3px solid var(--dark-blue);
			}
		}
	}
}
.dark-theme {
	.splide-carousel {
		.splide__arrow {
			background-color: #0B1C27;
			border: 1px solid #FFF;
			.icon {
				fill: #FFF;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.splide-carousel {
		.splide__arrows {
			margin-top: -27px;
		}
		.splide__track {
			margin: 0 56px;
		}
		.splide__pagination {
			margin-top: 40px;
		}
		&.no-navigation {
			.splide__track {
				margin: 0;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
