//Mobile------------------------------------------------------------------------------------------------------
.hero-podcast {
	position: relative;
	padding-bottom: 70px;
	margin-bottom: 70px;
	color: var(--text-color);
	.breadcrumb-container {
		padding-top: 28px;
		padding-bottom: 34px;
	}
	.image {
			position: absolute;
			inset: 0;
			padding-top: 0;
			&.gradient {
				&:after {
					background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50.38%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44267) 37.31%, rgba(0, 0, 0, 0.6) 100%), ;
				}
			}
		}
	.text-zone,
	.thumbnail {
		position: relative;
	}
	.thumbnail {
		overflow: hidden;
		border-radius: var(--button-radius);
		.d-lg-none {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			.label {
				display: none;
			}
		}
	}
	.button-video {
		.icon-wrapper {
			width: 72px;
			height: 72px;
			.icon {
				width: 32px;
				height: 32px;
			}
			&:before {
				width: 72px;
				height: 72px;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.hero-podcast {
		padding-bottom: 70px;
		margin-bottom: 70px;
		.breadcrumb-container {
			padding-top: 88px;
			padding-bottom: 28px;
		}
		.text-zone,
		.thumbnail {
			margin-top: 76px;
		}
	  .button-video {
			margin-top: 48px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
