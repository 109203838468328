//Mobile------------------------------------------------------------------------------------------------------
.search-results {
	.result {
		margin-bottom: 24px;
		box-shadow: 0px 79px 31px rgba(130, 168, 192, 0.01), 0px 44px 27px rgba(130, 168, 192, 0.03), 0px 20px 20px rgba(130, 168, 192, 0.05), 0px 5px 11px rgba(130, 168, 192, 0.06), 0px 0px 0px rgba(130, 168, 192, 0.06);
		&:not(:last-child) {

		}
		border: 1px solid var(--card-border);
		line-height: 1.3333333333333333;
	}
	h3 {
		font-size: 1.1111111111111112rem;
		font-weight: 700;
		em {
			font-style: normal;
			font-weight: normal;
			font-size: 0.7em;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Mobile only ---------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {
	.search-results {
		.result {
			font-weight: 700;
			font-size: 1.125rem;
			.simple-cta {
				justify-content: flex-end;
			}
		}
	}
}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
