//Mobile------------------------------------------------------------------------------------------------------
.horizontal-media {
	padding: 16px 24px 16px 16px;
	background-color: #0A1A23;
	border-radius: 4px;
	color: #FFF;
	.image {
		position: relative;
		inset: auto;
		min-height: 100%;
		border-radius: 4px;
		padding-top: 0;
	}
	.button-video {
		position: absolute;
		margin-top: 0;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);

	}
	.image-zone {
		flex-grow: 1;
		width: 45%;
	}
	.horizontal-media-text-zone {
		width: 55%;
		padding-left: 16px;
	}
	h2{
		font-size: 1.125rem;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		font-weight: 600;
	}
	p {
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.horizontal-media {
		padding: 24px 24px 24px 16px;
		p {
			font-size: 0.889rem;
		}
		.horizontal-media-text-zone {
			padding-left: 24px;
		}
		.button-video {
			.icon-wrapper {
				width: 40px;
				height: 40px;
				&:before {
					width: 40px;
					height: 40px;
				}
				.icon {
					width: 18px;
					height: 18px;
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
