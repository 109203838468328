//Mobile------------------------------------------------------------------------------------------------------
.card-image-overlay {
	overflow: hidden;
	border-radius: 4px;
	position: relative;
	background-color: var(--hero-blue);
	.image {
		padding-top: 76.97%;
		&:after {
			background: linear-gradient(180deg, rgba(2, 47, 102, 0) 20.5%, rgba(2, 47, 102, 0.648) 53.41%, rgba(2, 47, 102, 1) 80.49%);
		}
	}
	.card-texts {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
	h3,p {
		color: #FFF;
	}
	h3 {
		font-size: 1.5rem;
	}
	.simple-cta {
		color: #FFF;
		.icon {
			fill: #FFF;
		}
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.card-image-overlay {
		.image {
			padding-top: 138.55%
		}
		h3 {
			font-size: 1.333rem;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
