//Mobile------------------------------------------------------------------------------------------------------
.card-icon {
	padding: 16px;
	background-color: #FFF;
	border: 1px solid var(--card-border);
	filter: drop-shadow(0px 79px 31px rgba(130, 168, 192, 0.01)) drop-shadow(0px 44px 27px rgba(130, 168, 192, 0.03)) drop-shadow(0px 20px 20px rgba(130, 168, 192, 0.05)) drop-shadow(0px 5px 11px rgba(130, 168, 192, 0.06)) drop-shadow(0px 0px 0px rgba(130, 168, 192, 0.06));
	min-height: 100%;
	border-radius: 4px;
	overflow: hidden;
	.icon {
		width: 33px;
		height: 33px;
		fill: var(--color-primary);
	}
	@extend .card-news;
	h3,h4 {
		a{
			font-weight: 600;
			&.title-big {
				.icon {
					display: block;
					margin-bottom: 8px;
					width: 50px;
					height: 50px;
				}
				font-size: 2rem;
			}
		}
	}
	.bg-icon {
		width: 50px;
		height: 50px;
		fill:  rgba(0, 95, 115, 0.08);
		position: absolute;
		left: -10px;
		bottom: -20px;
	}

	&.card-icon-circle {

		.icon-wrapper {
			border-radius: 50%;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--blue-light);
			flex-shrink: 0;
			.icon {
				width: 20px;
				height: 20px;
			}
		}
		.card-main {
			flex-grow: 1;
		}
		h3,h4 {
			font-size: 1.1111111111111112rem;
			width: calc(100% - 48px);
			min-height: 2.7777777777777777rem;
			a {
				display: -webkit-box !important;
				-webkit-line-clamp: 2;
			}
		}
		p {
			margin-left: 48px;
		}
		.justify-content-end {
			width: 100%;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.card-icon {
		min-height: 100%;
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.card-icon {
		h3,h4 {
			a{
				&.title-big {
					font-size: 1.7777rem;
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
