//Mobile------------------------------------------------------------------------------------------------------
.sticky-header {
	.it-header-slim-wrapper {

	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.sticky-header {
		.it-header-slim-wrapper {
			background-color: var(--header-slim-sticky-bg);
			.it-header-slim-wrapper-content {
				height: 40px;
				a {
					color : var(--header-slim-sticky-link-color);
					.icon {
						fill: var(--header-slim-sticky-link-color);
					}
				}
				.navbar-brand {
					font-size: var(--header-slim-sticky-font-size);
					color: var(--header-slim-sticky-brand-color);
				}
				.nav-item.dropdown {
					opacity: 0;
					pointer-events: none;
					visibility: hidden;
					transition: opacity 0.3s, visibility 0s 0.3s;
				}
				.secondary-nav {
					border-left: 1px solid transparent;
				}
			}
			.icon-rep-wrapper {
				width: 28px;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.it-header-slim-wrapper {
		transition: all 0.3s;
		.it-header-slim-wrapper-content {
			.navbar-brand,
			.secondary-nav,
			.nav-item.dropdown,
			.it-header-slim-wrapper-content {
				transition: all 0.3s;
				a {
					transition: all 0.3s;
				}
			}
		}
	}
}
