//Mobile------------------------------------------------------------------------------------------------------
.it-header-center-wrapper {
	padding-left: 0;
	padding-right: 0;
	.it-header-center-content-wrapper {
		.it-brand-wrapper {
			a {
				.icon {
					width: var(--header-center-mob-size);
					height: var(--header-center-mob-size);
				}
			}
		}
	}
}
//-mobile only
@include media-breakpoint-down(md) {
	.it-header-center-wrapper {

		.it-header-center-content-wrapper {
			.it-brand-wrapper {
				a {
					.it-brand-title {
						font-size: var(--header-center-mob-title-size);
					}
					.it-brand-tagline {
						font-size:  var(--header-center-mob-subtitle-size);
					}
				}
			}
		}
	}

}
@include media-breakpoint-down(lg) {
	.it-header-center-wrapper {
		background-color: transparent;
		height: auto;
		.it-header-center-content-wrapper {
			.it-brand-wrapper a {
				color: var(--color-primary);
			}
			.it-brand-wrapper a .icon{
				fill: var(--color-primary);
			}
		}
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.it-header-center-wrapper {
		background-color: #FFF;
		.it-header-center-content-wrapper .it-brand-wrapper a .icon {
			fill: var(--color-primary);
		}
		.it-header-center-content-wrapper .it-brand-wrapper a {
			color: var(--color-primary);
		}
		.it-header-center-content-wrapper {
			padding-left: var(--default-x-padding-desk);
			padding-right: var(--default-x-padding-desk);
			.it-brand-wrapper {
				a {
					.icon {
						width: var(--header-center-desk-size);
						height: var(--header-center-desk-size);
					}
				}
			}
		}
	}
	//-versione trasparente
	.transparent-header {
		.it-header-center-wrapper {
			background-color: transparent;
		}
		.it-header-center-content-wrapper .it-brand-wrapper a .icon {
			fill: #FFF;
		}
		.it-header-center-content-wrapper .it-brand-wrapper a {
			color: #FFF;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.it-header-center-wrapper {
		transition: all 0.3s;
		overflow: hidden;
	}
}
