//Mobile------------------------------------------------------------------------------------------------------
.table-doc {
	.icon {
		transition: all 0.3s;
	}
	table {
		width: 100%;
		font-size: 0.889rem;
		th:first-child {
			width: 50%;

		}
		th {
			position: relative;
		}
		th:not(:first-child):not(:last-child):after {
			content: '';
			width: 1px;
			height: 32px;
			background-color: var(--text-color);
			display: block;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		thead {
			background-color: #F5F9FC;
		}
		.simple-cta {
			border: none;
			background: none;
			&.download {
				font-size: 0.889rem;
				text-transform: none;
			}
		}
		.collapsable {
			&[aria-expanded="true"] {
				.icon {
					transform: rotate(180deg);
				}
			}
		}
		td.lined {
			border-bottom: 1px solid #E9F0F5;
		}
		tr {
			transition: all 0.3s;
		}
		.data-content {
			background-color: #F5F9FC;
		}
		.doc-rows {
			background-color: #FFF;
			border: 1px solid #E9F0F5;
			border-radius: 4px;
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}

}
.pager {
	background-color: #EDF4F7;
	.pagination	{
		margin-bottom: 0;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
