//Mobile------------------------------------------------------------------------------------------------------
.it-header-slim-wrapper {
	background-color: var(--header-slim-bg-color);
	height: auto;
	border-bottom: 1px solid var(--border-color-negative);
	.it-header-slim-wrapper-content {
		height: auto;
		padding: 16px 0;
		a .icon {
			width: var(--icon-size);
			margin-left: 0;
			flex-shrink: 0;
			&.world-icon {
				margin-right: 10px;
			}
			&.chevron-icon {
				margin-left: 14px;
			}
		}
		.it-header-slim-right-zone {
			height: 100%;
			font-size: var(--header-slim-right-font-size);
		}
		a.dropdown-toggle {
			padding-top: 0;
			padding-bottom: 0;
			font-size: var(--header-slim-right-font-size);
			text-transform: none;
		}
		.nav-mobile ul.link-list a {
			padding-top: 0;
			padding-bottom: 0;
		}
		.secondary-nav {
			border-left: 1px solid #FFF;
			padding-left: 30px;
			margin-left: 30px;
			ul {
				list-style-type: none;
			}
		}
		.dropdown-menu {
			top: 0 !important;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.it-header-slim-wrapper {
		background-color: #FFF;
		border-bottom: 1px solid var(--card-border);
		.icon-rep-wrapper {
			width: 0;
			overflow: hidden;
			.icon {
				fill: var(--header-slim-bg-color);
				flex-shrink: 0;
			}
		}
		.it-header-slim-wrapper-content {
			padding-top: 0;
			padding-bottom: 0;
			height: 56px;
			padding-left: var(--default-x-padding-desk);
			padding-right: var(--default-x-padding-desk);
			a {
				color: var(--color-primary);
				.icon {
					fill: var(--color-primary);
				}
				.icon-rep-wrapper .icon {
					fill: var(--text-color);
				}
			}

			.navbar-brand {
				font-size: 1rem;
				color: var(--text-color);
			}
		}
	}
	.transparent-header {
		.it-header-slim-wrapper {
			background-color : var(--header-slim-bg-color);
			border-bottom: 1px solid var(--border-color-negative);
			.icon-rep-wrapper .icon {
				fill: #FFF;
			}
			.it-header-slim-wrapper-content {
				a {
					color: #FFF;
					.icon {
						fill: #FFF;
					}
				}
				.navbar-brand {
					color: #FFF;
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.it-header-slim-wrapper {
		.it-header-slim-wrapper-content {
			.icon-rep-wrapper {
				transition: all 0.3s;
			}
			.navbar-brand {
				transition: all 0.3s;
			}
		}
	}
}
