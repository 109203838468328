//Mobile------------------------------------------------------------------------------------------------------
.hero-full-search {
	position: relative;
	overflow: hidden;
	background-color: var(--hero-blue);
	.image {
		position: absolute;
		min-height: 100%;
		top: 0;
		left: 0;
		width: 100%;
	}
	.image.gradient {
		&:after {
			background: linear-gradient(90.49deg, rgba(2, 47, 102, 0) -40.55%, rgba(2, 47, 102, 0.8) 37.7%, var(--hero-blue) 61.37%);
			transform: rotate(-180deg);
		}
	}
	.text-zone {
		position: relative;
		color: #FFF;
		padding: var(--default-x-padding-desk) 0;
	}
	.search-panel {
		position: relative;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 36px;

	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.hero-full-search {
		.image {
			padding-top: 0;
		}
		.text-zone {
			padding: var(--default-x-padding-desk);
			width: 52%;
		}
		p {
			font-size: 0.889rem;
		}
		.search-panel {
			position: relative;
			padding-left: var(--default-x-padding-desk);
			padding-right: var(--default-x-padding-desk);
			padding-bottom: 56px;
			.search-panel-content {
				padding: 12px 8px;
			}
		}
		&.branded {
			position: relative;
			&:after {
				content: '';
				display: block;
				position: absolute;
				inset: 0;
				background: url('../assets/big-circle.svg') no-repeat;
				background-size: 477px;
				background-position: -10px 390px;
				z-index: 1;
				pointer-events: none;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
