//Mobile------------------------------------------------------------------------------------------------------
.pill {
	font-size: 0.75rem;
	text-transform: uppercase;
	color: #FFF;
	font-weight: 600;
	background-color: var(--color-primary);
	border-radius: 20px;
	padding: 4px 16px;
	line-height: 1.2;
	&.pill-dark {
		background-color: var(--pill-dark);
	}
	&.pill-green {
		background-color: var(--green);
		color: #1A1A1A;
	}
	&.pill-light {
		background-color: var(--pill-light);
		color: #1A1A1A;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.pill {
		font-size: 0.667rem;
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
