//Mobile-------------------------------------------------------------------------------------------------
.icon {
	width: var(--icon-size);
	height: var(--icon-size);
	&.icon-xs {
		width: var(--icon-size-xs) !important;
		height: var(--icon-size-xs) !important;
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}
