//Mobile------------------------------------------------------------------------------------------------------

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}
//Under Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-down(lg) {

}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.sticky-header {
		&.transparent-header {
			.it-header-navbar-wrapper {
				background-color: var(--dark-blue);
			}
		}
		.it-header-navbar-wrapper {
			padding: 5px 0;
			background-color: var(--dark-blue);
			.search-zone {
				opacity: 1;
				pointer-events: all;
				visibility: visible;
			}
		}

	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
