//Mobile------------------------------------------------------------------------------------------------------
body {
	background-color: var(--body-background);
	&.overflow-visible {
		&.modal-open {
			padding-right: 0 !important;
		}
	}
}

a {

}
.padding-under-header {
	padding-top: var(--header-mob-size) !important;
}

button {
	margin: 0;
}
.padding-top-section-y {
	padding-top: var(--default-y-padding-mob);
}
.padding-top-section-y-big {
	padding-top: var(--big-y-padding-mob);
}
.padding-bottom-section-y {
	padding-bottom: var(--default-y-padding-mob);
}
.padding-bottom-section-y-big {
	padding-bottom: var(--big-y-padding-mob);
}
.margin-top-section-y {
	margin-top: var(--default-y-padding-mob);
}
.margin-bottom-section-y {
	margin-bottom: var(--default-y-padding-mob);
}
.row {
	--bs-gutter-x: 16px;
}

[target="_blank"]:after {
	content: var(--text-blank);
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.bg-primary-lighter {
	background-color: var(--blue-lighter);
}

main {
	color: var(--text-color);
}

//Mobile tablet only ---------------------------------------------------------------------------------------
@include media-breakpoint-down(lg) {
	main {
		padding-bottom: 64px;
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.margin-under-header {
		margin-top: var(--header-tab-size) !important;
	}
}
//Down xxl--------------------------------------------------------------------------------------
@include media-breakpoint-down(xxl) {
	.nopad-xl {
		padding-left: 0 !important;
		padding-right: 0 !important;
		overflow-x: hidden;
	}
}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.padding-under-header {
		padding-top: var(--header-desk-size) !important;
	}
	.padding-under-header.small-padding {
		padding-top: 177px !important;
	}
	.padding-x-lg {
		padding-left: var(--default-x-padding-desk) !important;
		padding-right: var(--default-x-padding-desk) !important;
	}
	.padding-top-section-y {
		padding-top: var(--default-y-padding-desk);
	}
	.padding-top-section-y-big {
		padding-top: var(--big-y-padding-desk);
	}
	.padding-bottom-section-y {
		padding-bottom: var(--default-y-padding-desk);
	}
	.padding-bottom-section-y-big {
		padding-bottom: var(--big-y-padding-desk);
	}
	.margin-top-section-y {
		margin-top: var(--default-y-padding-desk);
	}
	.margin-bottom-section-y {
		margin-bottom: var(--default-y-padding-desk);
	}

	.sidebar-content {
		section {
			.container-xxl {
				padding: 0;
			}
		}
		.section-bg-light {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				inset: -16px;
				width: calc(100% + 32px);
				height: calc(100% + 32px);
				background-color: var(--blue-light);
				z-index: -1;
				border-radius: 4px;
			}
		}
	}
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	button,
	input[type="checkbox"] + label,
	input[type="radio"] + label {
		&:hover {
			cursor: pointer;
		}
	}
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
}
